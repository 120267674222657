import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export function Notfound(){

    const navigate = useNavigate()

    useEffect(() =>
    {
    setTimeout(()=>{
        navigate("/")
    },3000)

    },[])
return <h1 style={{color:'black'}}> You have been navigated to a link which is not found !!! You would be redirected to homepage shortly!</h1>

}