import React, { useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import PlawrForm from "./Form";
import TeamForm from "./TeamForm";
const FormPopup = ({ setclosebutton, selectedrecord, isPlaywright }) => {
  return (
    <>
      {console.log({ setclosebutton })}
      <div
        className="Background"
        style={{
          width: "100vw",
          position: "absolute",
          top: "130px",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          overflowY: "scroll",
        }}
      >
        <div
          className="Container"
          style={{
            height: "80vh",
            borderRadius: "12px",
            backgroundColor: "white",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            display: "flex",
            flexDirection: "column",
            padding: "25px",
            overflowY: "scroll",
          }}
        >
          <CloseOutlined
            onClick={() => setclosebutton(false)}
            style={{ backgroundcolor: "crimson", color: "red", width: "10px" }}
          />
          {isPlaywright === true ? (
            <PlawrForm selectedrecord={selectedrecord} />
          ) : (
            <TeamForm selectedrecord={selectedrecord} />
          )}
        </div>
      </div>
    </>
  );
};

export default FormPopup;
