import React, { useRef, useState, useEffect } from "react";
import { Input, Space } from "antd";
import {
  MinusCircleOutlined,
  PlusOutlined,
  BorderBottomOutlined,
} from "@ant-design/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Button, Form, Select, Upload, Modal } from "antd";
import "./Reactquill.css";
import axios from "axios";
const { Option } = Select;

//modules for react quill parameters
const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    ["link", "image", "video"],
    ["clean"],
  ],
};

//
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },

  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const PlawrForm = ({ selectedrecord }) => {
  const [isnewrec, setisnewrec] = useState("false");

  const [formData, setFormData] = useState({});
  console.log(selectedrecord);

  const handleInputChange = (e) => {
    console.log(e);
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const addPlaywright = async (data) => {
    try {
      let result = await axios.post(
        process.env.REACT_APP_BASE_URL + "/api/v1/playwright/add-playwright",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("result: ", result);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = (values) => {
    //e.preventDefault();
    console.log({ values });
    // Access the item data in formData and perform actions (e.g., API call, validation)
    // if (isnewrec === true) {
    //   let updre = values;
    //   console.log(updre.bio);
    //   delete updre.Id;
    //   console.log("form out: ", updre);
    //   console.log("files: ", fileList);
    //   updre.image = fileList[0].originFileObj;
    //   let formData = new FormData();
    //   for (const [key, value] of Object.entries(updre)) {
    //     console.log(`${key}: ${value}`);
    //     formData.append(key, value);
    //   }

    //   addPlaywright(formData);
    // } else {
    //   console.log({ values });
    // }
  };

  const [value, setValue] = useState("");

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);

  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleRemove = (file) => {
    const newFileList = fileList.filter((item) => item.uid !== file.uid);
    form.setFieldsValue({ image: newFileList });
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 20,
        }}
      >
        Upload
      </div>
    </div>
  );
  const [form] = Form.useForm();

  React.useEffect(() => {
    let files = [];

    if (
      selectedrecord === null ||
      selectedrecord === undefined ||
      selectedrecord === ""
    ) {
      console.log("selected record: ", selectedrecord);
      setisnewrec(true);
      form.setFieldsValue({
        name: "",
        country: "",
        gender: "FeMale",
        related_links: [],
        bio: "",
        bio_french: "",
        image: "",
      });
    } else {
      console.log("selected record:else ", selectedrecord);
      setisnewrec(false);
      form.setFieldsValue(selectedrecord);
    }

    if (
      selectedrecord.image === null ||
      selectedrecord.image === undefined ||
      selectedrecord.image === ""
    ) {
      files = [{}];
    } else {
      files = [
        {
          uid: "1",
          name: "file1.jpg",
          status: "done",
          url: selectedrecord.image,
        },
      ];
      setFileList(files);

      form.setFieldsValue(selectedrecord);
    }
  }, []);

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
    });
  };

  const handleContentChange = (value) => {
    console.log({ value });
  };
  return (
    <>
      <Form
        form={form}
        {...formItemLayout}
        onFinish={handleSubmit}
        style={{
          maxWidth: "600",
          height: "80vh",
        }}
        scrollToFirstError
      >
        <Form.Item name="Id"> </Form.Item>

        <Form.Item
          name="name"
          label="Playwright Name"
          rules={[
            {
              required: true,
              message: "Please enter Playwright name!",
            },
          ]}
        >
          <Input placeholder="Enter the name of the playwright"></Input>
        </Form.Item>

        <Form.Item
          name="country"
          label="Country"
          rules={[
            {
              required: true,
              message: "Please enter Country name!",
            },
          ]}
        >
          <Input placeholder="Enter the Country of the playwright"></Input>
        </Form.Item>

        <Form.Item
          name="gender"
          label="Gender"
          rules={[
            {
              required: true,
              message: "Please select Gender!",
            },
          ]}
        >
          <Select placeholder="Please select the Gender">
            <Option value="Male">Male</Option>
            <Option value="Female">Female</Option>
            <Option value="Other">Other</Option>
          </Select>
        </Form.Item>

        <Form.List name="related_links" label="related_links">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={{
                    display: "flex",
                    marginBottom: 8,
                    justifyContent: "center",
                  }}
                  align="baseline"
                >
                  <MinusCircleOutlined onClick={() => remove(name)} />
                  <Form.Item
                    // className="ant-col-xs-24 ant-col-sm-8"
                    {...restField}
                    name={[name, "url"]}
                  >
                    <Input
                      // className="ant-col-xs-24 ant-col-sm-16"
                      placeholder="Link"
                    />
                  </Form.Item>
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add field
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Form.Item
          name="bio"
          label="Bio"
          rules={[
            {
              required: true,
              message: "Please enter Bio!",
            },
          ]}
        >
          <ReactQuill
            modules={modules}
            theme="snow"
            value={formData.bio}
            placeholder="Content goes here..."
          />
        </Form.Item>

        <Form.Item
          name="bio_french"
          label="Bio-French"
          rules={[
            {
              required: true,
              message: "Please enter Translated Bio!",
            },
          ]}
        >
          <ReactQuill
            modules={modules}
            theme="snow"
            value={value}
            placeholder="Content goes here... "
            onChange={form.setFieldValue({ ["bio_french"]: value })}
          />
        </Form.Item>

        <Form.Item
          name="image"
          label="Picture" /*rules={[
        {
          required: true,
          message: 'Please upload a picture!',
          
        },]}*/
        >
          {/*
   <Upload name="logo" action="/upload.do" listType="picture" maxCount={1} defaultFileList={files}>
        <Button icon={<UploadOutlined />}>Click to upload</Button>
    </Upload>*/}

          {/* https://codepen.io/pen?&editors=001 */}

          <Upload
            maxCount={1}
            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
            onRemove={handleRemove}
          >
            {fileList.length >= 1 ? null : uploadButton}
          </Upload>
          <Modal
            open={previewOpen}
            title={previewTitle}
            footer={null}
            onCancel={handleCancel}
          >
            <img
              alt="example"
              style={{
                width: "100%",
              }}
            />
          </Modal>
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default PlawrForm;
