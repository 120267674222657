import React,{useContext, useState} from 'react'


const LangContext = React.createContext()
const LangUpdateContext = React.createContext()

export function useLang()
{
    return useContext(LangContext)
}

export function useLangUpdate()
{
    return useContext(LangUpdateContext)
}


export function LangProvider({children})
{
      const [selectedOption, setSelectedOption] = useState('English')

      function handleOptionChange (selectedValue)  {
        
        setSelectedOption(selectedValue);
      }


      return(
        <LangContext.Provider value={selectedOption}>
            <LangUpdateContext.Provider value={handleOptionChange}>
              {children}
            </LangUpdateContext.Provider>
        </LangContext.Provider>

      )
}
