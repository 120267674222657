import React from "react";
import { Space, Row, Col, Image, Button } from "antd";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";
import { useLang, useLangUpdate } from "../Context/languagecontext";
import "./LandingPage.css";

const LandingPage = () => {
  const selectedOption = useLang();
  const handleChange = useLangUpdate();

  const navigate = useNavigate();
  const navigateToDatabase = () => {
    console.log("In function");
    navigate("/database");
  };

  return (
    <div
      style={{
        border: 0,
        margin: 0,
        height: "100%",
      }}
    >
      <div className="welcome_div">
        {/* position: "relative",
          display: "flex",
          justifyContent: "center",
          backgroundImage: "url(./Logo_white.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          backgroundPosition: "center", */}
        <Row
          gutter={["9%", "9%"]}
          style={{
            height: "100%",
            width: "60%",
            flexDirection: "Row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Col
            style={{
              width: "20%",
              height: "100%",
              position: "relative",
              display: "flex",

              justifyContent: "center",
              backgroundImage: "url(./Welcome_Border.png)",
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
              backgroundPosition: "center",
            }}
          ></Col>
          <Col style={{ width: "33.3%" }}>
            {" "}
            <h1
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "3vw",
                margin: "0",
                fontFamily: "BadTyp",
              }}
            >
              {selectedOption === "English" ? "WELCOME" : "Bienvenue"}
            </h1>
          </Col>
          <Col
            style={{
              width: "20%",
              height: "100%",
              position: "relative",
              display: "flex",
              justifyContent: "center",
              backgroundImage: "url(../Welcome_Border.png)",
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
              backgroundPosition: "center",
            }}
          ></Col>
        </Row>
      </div>

      {/*<Space size={18} style={{border:'0',margin:'0%'}}> <h1 style={{color:'#F1621B',fontStyle:'oblique',fontWeight:'bold',fontSize:'450%',margin:'0'}}>  #  #  #  </h1>  <h1 style={{color:'black',fontWeight:'bold',fontSize:'300%',margin:'0',fontFamily:'BadTyp'}}>  WELCOME  </h1>   <h1 style={{color:'#F1621B',fontWeight:'bold',fontSize:'450%',margin:'0'}}>  #  #  # </h1></Space>*/}
      <div
        style={{
          margin: "0% 0% 2% 0%",
          height: "fit-content",
          width: "100vw",
        }}
      >
        <div style={{ marginTop: "2%", width: "100vw" }}>
          <Row
            gutter={[2, 1]}
            style={{
              backgroundColor: "#F9BD4D",
              marginBottom: "2%",
              height: "100%",
              padding: "0%",
            }}
          >
            <Col
              span={1}
              // style={{
              //   backgroundImage: "url(./Whoweare_imagepart_Leftborder.png)",
              //   backgroundRepeat: "no-repeat",
              //   backgroundSize: "100% 100%",
              //   backgroundPosition: "center",
              // }}
            >
              <img
                src="./Whoweare_imagepart_Leftborder.png"
                class="left_image"
                alt="left border"
              />
            </Col>

            <Col
              span={14}
              style={{
                display: "flex",
                flexDirection: "Column",
                justifyContent: "flex-start",
                alignItems: "center",
                marginTop: "2%",
              }}
            >
              {/* <h1 style={{display:'flex',color:'#2C5632',fontSize:'350%',textAlign:'start',alignItems:'start',fontWeight:'bolder',margin:'1% 1%',fontFamily:'BadTyp',whiteSpace: 'pre-line'}}>{(selectedOption=== 'English')?'WHO \n WE ARE':'OMS \n NOUS SOMMES'}</h1>
               */}
              <div style={{ height: "4vh", margin: "6%" }}>
                <ReactSVG
                  src="./Community Andrinka.svg"
                  className="svg_green"
                  preview={false}
                ></ReactSVG>
              </div>
              <br></br>
              <p className="welcome_text">
                {" "}
                {selectedOption === "English"
                  ? "African Theatres in Translation showcases the profiles of African playwrights writing in French. The website aims to create bridges that traverse language, cultural, and geographic barriers. It was designed and launched in 2023 to connect scholars, students, and theatre artists around the globe who work in English with theatremakers from the African Diaspora who write and work predominantly in French. It will continue to expand to include more theatre makers from Africa and the Diaspora."
                  : "Le Théâtre African Traduit (ou en Traduction) présente les profils de dramaturges africains écrivant en français. Ce site Web vise à créer des liens qui vont au-delà des barrières linguistiques, culturelles et géographiques. Il a été conçu et lancé en 2023 pour connecté des universitaires, des étudiants et des artistes du théâtre du monde entier qui travaillent en Anglais avec des créateurs de théâtre de la Diaspora Africaine qui écrivent et travaillent en français. Ce site Web continuera à s'épanouir pour inclure davantage de créateurs de théâtre d'Afrique et de la diaspora."}
                {/*(selectedOption=== 'English')?'Our mission is to foster accessibility and promote African playwrights and theaters to a global audience in diverse languages. We believe in the power of storytelling and the richness of African narratives, which deserve to be celebrated and shared on a broader scale. By bridging language barriers, we aim to create a platform that enables individuals from all backgrounds to engage with the vibrant world of African theater. Through our efforts. we aspire to amplify the voices of African playwrights, celebrate their cultural heritage, and cultivate a deeper understanding and appreciation for the diverse theatrical traditions across the African continent. Our commitment to inclusivity and accessibility fuels our drive to provide translations, adaptations. and resources that empower people worldwide to connect with African plays, fostering cross-cultural dialogue and fostering a global appreciation for African theatrical artistry'
    :"Notre mission est de favoriser l'accessibilité et de promouvoir les dramaturges et théâtres africains auprès d'un public mondial dans diverses langues. Nous croyons au pouvoir de la narration et à la richesse des récits africains, qui méritent d'être célébrés et partagés à plus grande échelle. En surmontant les barrières linguistiques, nous visons à créer une plate-forme qui permet aux individus de tous horizons de s'engager dans le monde dynamique du théâtre africain. Grâce à nos efforts. nous aspirons à amplifier les voix des dramaturges africains, à célébrer leur héritage culturel et à cultiver une compréhension et une appréciation plus profondes des diverses traditions théâtrales à travers le continent africain. Notre engagement envers l'inclusivité et l'accessibilité alimente notre volonté de fournir des traductions, des adaptations. et des ressources qui permettent aux gens du monde entier de se connecter avec les pièces africaines, favorisant le dialogue interculturel et favorisant une appréciation globale de l'art théâtral africain"
          */}
              </p>
            </Col>

            {/*<Col span={10} style={{padding:'0%',backgroundImage:`url(./Whoweare_imagepart2.png)`,backgroundSize:'cover',backgroundRepeat:"no-repeat"}} ></Col> */}
            <Col span={9}>
              <img
                src="./Home Page-Main.png"
                class="left_image"
                alt="left border"
              />
            </Col>
          </Row>

          <Row
            gutter={[4, 1]}
            style={{
              margin: "0%",
              height: "fit-content",

              backgroundColor: "#BFCCC2",
              marginTop: "1%",
            }}
          >
            <Col xs={6} sm={6} md={6} lg={4} className="photo-class">
              {
                /*<div className="image-container"><Image className="image" src='./David Minor Ilunga.jpg' width='100%' height='100%' preview={false}/></div> */ <Image
                  src="./DavidMinorIlunga.jpg"
                  width="100%"
                  height="100%"
                  preview={false}
                />
              }
            </Col>
            <Col xs={6} sm={6} md={6} lg={4} className="photo-class">
              <Image
                src="./Jerome_Tossavi.png"
                width="100%"
                height="100%"
                preview={false}
              />
            </Col>
            <Col xs={6} sm={6} md={6} lg={4} className="photo-class">
              <Image
                src="./Joel.jpg"
                width="100%"
                height="100%"
                preview={false}
              />
            </Col>
            <Col xs={6} sm={6} md={6} lg={4} className="photo-class">
              <Image
                src="./Hermine.jpeg"
                width="100%"
                height="100%"
                preview={false}
              />{" "}
            </Col>

            <Col
              xs={24}
              sm={24}
              md={24}
              lg={8}
              style={{
                backgroundColor: "#BFCCC2",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h1
                style={{
                  display: "flex",
                  color: "#2C5632",
                  fontSize: "3vw",
                  textAlign: "center",
                  alignItems: "start",
                  fontWeight: "bolder",
                  margin: "2% 1% 0% 1%",
                  fontFamily: "BadTyp",
                  whiteSpace: "pre-line",
                }}
              >
                {selectedOption === "English"
                  ? "Meet the Playwrights"
                  : "Rencontrez les dramaturges"}
              </h1>
              <h3 className="meet-text">
                {selectedOption === "English"
                  ? "Click on an image to read the profile, or visit the link above to search the full collections of playwrights behind the stories"
                  : "cliquez sur une image pour lire le profil ou visitez le lien ci-dessus pour rechercher les collections complètes de dramaturges derrière les histoires"}
              </h3>
              <Button
                type="primary"
                style={{
                  margin: "1% 1% 2% 1%",
                  backgroundColor: "#2C5632",
                }}
                onClick={navigateToDatabase}
                size="small"
              >
                {selectedOption === "English"
                  ? "Learn More"
                  : "Pour en apprendre encore plus…"}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
