import React, { useState } from "react";
import { Space, Row, Col, Image, Button, Input } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { ReactSVG } from "react-svg";
import { useLang } from "../Context/languagecontext";

const Contact = () => {
  const selectedOption = useLang();
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const sendmail = () => {
    let email = "info@africantheatresintranslation.org";
    console.log("name , sub mes: ", name, subject, message);
    let link = `mailto:${email}?subject=${subject}&body=${message}`;
    window.open(link);
  };
  return (
    <>
      <div
        style={{
          margin: "2% 0% 2% 0%",
          minHeight: "40vh",
          maxHeight: "60vh",
          width: "100vw",
        }}
      >
        <Row
          // gutter={[4, 1]}
          style={{
            backgroundColor: "#F9BD4D",
            margin: "0%",
            height: "100%",
            padding: "0%",
          }}
        >
          <Col push={1} span={9}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "Column",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <h1
                style={{
                  color: "#F2772A",
                  fontSize: "3.5vw",
                  fontWeight: "bolder",
                  margin: "1% 1%",

                  fontFamily: "BadTyp",
                }}
              >
                {selectedOption === "English" ? "CONTACT US" : "CONTACTEZ-NOUS"}
              </h1>

              <p
                style={{
                  color: "black",
                  fontWeight: "400",
                  alignItems: "center",
                  margin: "1% 1%",
                  fontSize: "2vw",
                  fontFamily: "Rig Sans",
                }}
              >
                {selectedOption === "English"
                  ? "If you would like your profile to be added, or if you have general inquiries about the website project, please let us know"
                  : "Si vous souhaitez que votre profil soit ajouté ou si vous avez des questions générales sur le projet de site Web, veuillez nous en informer"}{" "}
              </p>

              <br></br>
              <Input
                size="small"
                placeholder={selectedOption === "English" ? "Name" : "Nom"}
                style={{
                  color: "black",
                  borderBottom: "solid 2px black",
                  width: "94%",
                  margin: "3%",
                  textAlign: "start",
                  fontFamily: "Rig Sans",
                  background: "transparent",
                }}
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Input>

              <br></br>
              <Input
                size="small"
                placeholder={
                  selectedOption === "English" ? "Subject" : "Sujette"
                }
                style={{
                  color: "black",
                  borderBottom: "solid 2px black",
                  width: "94%",
                  margin: "3%",
                  textAlign: "start",
                  fontFamily: "Rig Sans",
                  background: "transparent",
                }}
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              ></Input>

              <br></br>
              <Input
                size="small"
                placeholder={
                  selectedOption === "English" ? "Message" : "Message"
                }
                style={{
                  color: "black",
                  borderBottom: "solid 2px black",
                  width: "94%",
                  margin: "3%",
                  textAlign: "start",
                  fontFamily: "Rig Sans",
                  background: "transparent",
                }}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></Input>
              <Space
                onClick={() => sendmail()}
                size={2}
                style={{
                  width: "80%",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  // marginLeft: "2%",
                }}
              >
                {" "}
                <MailOutlined
                  style={{ fontSize: "4vw", color: "black" }}
                ></MailOutlined>{" "}
                <label
                  style={{
                    color: "black",
                    fontFamily: "Rig Sans",
                    fontSize: "2vw",
                  }}
                >
                  info@africantheatresintranslation.org{" "}
                </label>{" "}
              </Space>
            </div>
          </Col>
          <Col
            push={2}
            span={13}
            style={{ padding: "0%", height: "auto", width: "100%" }}
          >
            {/*<Image src='./ContactUs.png' style={{width:'58.33vw',height:'auto'}}  preview={false}/>*/}
            <img
              src="./ContactUs.png"
              style={{ width: "100%", height: "100%" }}
              alt="logo"
            ></img>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Contact;
