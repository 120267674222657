import { React, useState, useEffect } from "react";
import { Carousel, Image, Avatar } from "antd";
import { PhotoBackground } from "./styled_profile";
import { Row, Col } from "antd";
import { useLocation } from "react-router-dom";
import { useLang, useLangUpdate } from "../Context/languagecontext";
import { UserOutlined } from "@ant-design/icons";
import "./playwright_profile.css";

const Playwright_profile = () => {
  const selectedOption = useLang();
  const [bio_html, setBio] = useState("");
  const eng_text = `Daté Atavito Barnabé-Akayi’s dramatic creation, written by dramatists Florent Couao-Zotti or Kangni Alem… and theater specialist Pierre Médéhouégnon or his students Fernand Nouwligbéto and Romain Hounzandji…, promotes a manifestation of Liberty which allows him to show his support to all marginalized minorities, whether they come from modest or fortunate means or are concerned with politics or homosexuality. As the university Mahougnon Kakpo wrote, “Les confessions du PR (The Confessions of PR), like the two others, are both plays which are as good as the renowned playwrights who created them.” His writing strives to care for the human race from the cradle through a better education stripped of permissiveness and resignation.
    His well-known published plays, directed by Isidore Dokpa (Fitheb) or Hermas Gbaguidi (Benin, Nigeria, Togo, Ivory Coast) are:
    Le chroniqueur du PR (The Chronicler of PR) (2016). 2017 Laureate of the President of the Republic Prize.
    Les confessions du PR & Amour en infraction (The Confessions of PR & Love Infraction) (2010), 2017 Finalist of the President of the Republic Prize.
    Quand Dieu a faim… (When God is Hungry…) (2010), 2017 Finalist of the President of the Republic Prize. 
    Recipient of the 2017 President of the Republic Prize for his play Le chroniqueur du PR, author of about twenty works, Daté Atavito Barnabé-Akayi, born September 24, 1978 in Togo, is a Beninese teacher who won, in 2017-2018, the Grand Price “Tour d’Afrique en 24 fiches” (“Africa Circuit in 24 Cards”) from the Organisation Internationale de la Francophonie (OIF) (International Organization of French Speaking Countries) and the Fédération Internationale des Professeurs de Français (FIPF) (International Federation of French Professors) with an intensive training award for teachers of French foreign language in the Centre d’Approches Vivantes des Langues et des Médias (Center of Living Approaches to Languages and Medias) (Vichy-Alliance Française, France). Currently, he teaches Techniques of Expression and Methods of Communication at the Institut National Supérieur de Technologie Industrielle (INSTI) (National Superior Institute of Industrial Technology) of Lokossa and Université d’Abomey-Calavi (University of Abomey-Calavi) (Benin).  While preparing a Doctorate on the Aesthetic of Esotericism in the poetry of Mahougnon Kakpo, Mwèné Gabriel Okoundji et Nora Atalla, he has published several scientific articles on the theatre of Ousmane Alédji entitled “Analyse des codes théâtraux de la scène 13 de la pièce Omon mi d’Ousmane Alédji” (“Analyze the Theatrical Code of scene 13 in the play Omon mi by Ousmane Alédji”)
    `;
  const french_text = `La création dramatique de Daté Atavito Barnabé-Akayi, portée par les dramaturges Florent Couao-Zotti ou Kangni Alem… et le théâtrologue Pierre Médéhouégnon ou ses disciples Fernand Nouwligbéto et Romain Hounzandji…, exalte une manifestation de la Liberté qui lui permet de signifier son soutien à toutes les minorités marginalisées, qu’elles viennent des gens modestes ou fortunés, qu’elles concernent la politique ou l’homosexualité. Si l’universitaire Mahougnon Kakpo écrit que « Les confessions du PR, comme les deux autres, sont des pièces qui n’ont rien à envier à ce que des dramaturges de renom ont pu créer », c’est que sa plume travaille la prise en charge de la race humaine dès le berceau par une meilleure éducation dépourvue de laxisme et de fatalisme. 
    Ses théâtres édités, connus et mis en scène par Isidore Dokpa (Fitheb) ou Hermas Gbaguidi (Bénin, Niger, Togo, Côte d’Ivoire) sont :
    Le chroniqueur du PR (2016). Lauréat du Prix du Président de la République 2017. 
    Les confessions du PR & Amour en infraction (2010), Finaliste du Prix du Président de la République 2017.
    Quand Dieu a faim… (2010), Finaliste du Prix du Président de la République 2017.
    
    Prix du Président de la République 2017 pour sa pièce théâtrale Le chroniqueur du PR, auteur d’une vingtaine d’ouvrages, Daté Atavito Barnabé-Akayi, né le 24 septembre 1978 au Togo, est un enseignant béninois qui a remporté, en 2017-2018, le Grand Prix « Tour d’Afrique en 24 fiches » de l’Organisation Internationale de la Francophonie (OIF) et de la Fédération Internationale des Professeurs de Français (FIPF) avec une bourse de formation intensive pour enseignants de français langue étrangère au Centre d’Approches Vivantes des Langues et des Médias (Vichy-Alliance Française, France). Actuellement, il enseigne les Techniques d’Expression et Méthodes de Communication à l’Institut National Supérieur de Technologie Industrielle (INSTI) de Lokossa et à l’Université d’Abomey-Calavi (Bénin). Préparant un Doctorat sur l’Esthétique de l’ésotérisme dans la poésie de Mahougnon Kakpo, Mwèné Gabriel Okoundji et Nora Atalla, il a publié quelques articles scientifiques dont un sur le théâtre d’Ousmane Alédji intitulé « Analyse des codes théâtraux de la scène 13 de la pièce Omon mi d’Ousmane Alédji ».
    `;

  const location = useLocation();
  const [Profile, setProfile] = useState(location.state);
  useEffect(() => {
    setBio(Profile.bio + "<br/> <br/>" + Profile.bio_french);
    console.log("pfo: ", Profile);
  }, []);
  /*  
    const [selectedOption, setSelectedOption] = useState('');
      const [isenglish, setisenglish] = useState(true);
      

      const handleChange = (event) => {
      setSelectedOption(event.target.value);
      if(event.target.value==="eng_text"){
        
        setisenglish(true);
      }
      else
      {
        setisenglish(false);
        
      }
      
    };*/

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "Column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "1%",
        marginLeft: "1%",
        marginRight: "1%",
      }}
    >
      <div>
        <Avatar
          size={300}
          // src={
          //   Profile.image_path == null || undefined
          //     ? ""
          //     : process.env.REACT_APP_BASE_URL + "/" + Profile.image_path
          // }
          src={Profile.image_path}
          style={{ border: "20px solid #F9BD4D" }}
        />
      </div>
      <div>
        <div
          style={{
            fontSize: "25px",
            color: "#F0631F",
            fontWeight: "bold",
            margin: "10px",
            fontFamily: "BadTyp",
          }}
        >
          {Profile.name}
        </div>
        <br></br>
        <div
          style={{
            display: "flex",
            flexDirection: "Column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <label
            style={{
              fontSize: "15px",
              color: "black",
              fontWeight: "bold",
              width: "50%",
            }}
          >
            {selectedOption === "English"
              ? "Full Biography"
              : "Biographie complète"}
          </label>
          <p
            className="Paragraph_bio"
            dangerouslySetInnerHTML={{ __html: bio_html }}
            style={{
              lineHeight: "26px",
              color: "black",

              fontWeight: "400",
              textAlign: "start",
              alignItems: "center",
              whiteSpace: "pre-line",
            }}
          >
            {/* {Profile.bio}
            <br />
            <br />
            {Profile.biofren} */}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Playwright_profile;
