import styled from 'styled-components'


export const PhotoBackground = styled.div`
 background: #dbd3ba;
  color: '#fff',
  position: 'relative',
  top: 0,
  bottom: 0,
  textAlign: 'center',
`