import { Col, Layout, Menu, Row, Select, Image } from "antd";
import { ReactSVG } from "react-svg";
import { useState, useContext } from "react";
import { React } from "react";
import { ReactComponent as BackgroundSVG } from "../LogoTA.svg";
import { MenuOutlined } from "@ant-design/icons";
import "./headerNavigation.css";
import { Link, useNavigate } from "react-router-dom";

import { useLang, useLangUpdate } from "../Context/languagecontext";
import { renderToStaticMarkup } from "react-dom/server";

const HeaderNavigation = ({ handleOptionChange }) => {
  const selectedOption = useLang();
  const handleChange = useLangUpdate();
  const svgString = encodeURIComponent(renderToStaticMarkup(<BackgroundSVG />));
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();
  const NavigatetoHome = () => {
    navigate("/");
  };
  const menus = [
    {
      label: (
        <Link to="/">{selectedOption === "English" ? "Home" : "Accueil"}</Link>
      ),
      key: "home",
      // icon: <SettingOutlined />,
    },
    {
      label: (
        <Link to="/about">
          {selectedOption === "English" ? "About" : "À Propos"}/
        </Link>
      ),
      key: "about",
      // icon: <SettingOutlined />,
    },
    {
      label: (
        <Link to="/database">
          {selectedOption === "English" ? "Database" : "Base de données"}
        </Link>
      ),
      key: "database",
      // icon: <SettingOutlined />,
    },

    {
      label: (
        <Link to="/contact">
          {selectedOption === "English" ? "Contact" : "contacter"}
        </Link>
      ),
      key: "contact",
      // icon: <SettingOutlined />,
    },
  ];

  const expandedStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "center",
    width: "100%",
    position: "relative",
    /* border-top: 1px solid black; */
    backgroundColor: "#2c5632",
    color: "#A5ADB5",
    zIndex: 2,
  };
  const collapsedStyle = {
    display: "none",
  };

  return (
    /*
<div style={{height:'100%',display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          }}>
      <h1 style={{ color: "#A5ADB5", fontSize: "24px" }}>
    African Theatre Arts{" "}
  </h1>
        
  <Menu 
  style={{backgroundColor:'transparent',color:'#A5ADB5',flex:'auto' }}
   items={menus}
   mode="horizontal" />
  </div>
  
  */
    <>
      <Row
        className="header"
        between="xxl"
        middle="xs"
        align="top"
        style={{
          display: "flex",
          alignItems: "center",

          justifyContent: "space-between",

          width: "auto",
          alignContent: "space-between",
          marginLeft: "1%",
          marginRight: "2%",
          paddingTop: "0",
          paddingBottom: "0",
        }}
      >
        <Col
          xs={3}
          sm={3}
          md={2}
          xl={2}
          align="middle"
          style={{
            height: "100%",
            width: "100%",
            position: "relative",
            display: "flex",
            justifyContent: "start",
          }}
        >
          <img
            onClick={() => NavigatetoHome()}
            src="./Logo_white.png"
            style={{ width: "100%", height: "100%" }}
            alt="logo"
          />
        </Col>
        <Col xs={0} sm={0} md={13}>
          <Menu
            style={{
              margin: "0",
              border: "0",
              backgroundColor: "transparent",
              color: "#A5ADB5",
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              alignItems: "end",
              fontSize: "1.5vw",
            }}
            mode="horizontal"
          >
            <Menu.Item key="home">
              <Link to="/">
                {selectedOption === "English" ? "Home" : "Accueil"}
              </Link>
            </Menu.Item>
            <Menu.Item key="about">
              <Link to="/about">
                {selectedOption === "English" ? "About" : "À Propos"}
              </Link>
            </Menu.Item>
            <Menu.Item key="database">
              <Link to="/database">
                {selectedOption === "English" ? "Database" : "Base de données"}
              </Link>
            </Menu.Item>

            <div class="dropdown" style={{ height: "100%" }}>
              <button
                class="dropbtn"
                style={{ height: "100%", fontSize: "1.5vw" }}
              >
                {selectedOption === "English" ? "Language ▼" : "Langues?▼"}
              </button>
              <div class="dropdown-content">
                <label
                  type="button"
                  className="link-button"
                  onClick={() => handleChange("English")}
                >
                  {selectedOption === "English" ? "English" : "Anglais"}{" "}
                </label>
                <label
                  type="button"
                  className="link-button"
                  onClick={() => handleChange("Frech")}
                >
                  {" "}
                  {selectedOption === "English" ? "French" : "Français"}{" "}
                </label>
              </div>
            </div>
            <br></br>
            <Menu.Item key="contact">
              <Link to="/contact">
                {selectedOption === "English" ? "Contact" : "contacter"}
              </Link>
            </Menu.Item>
          </Menu>
        </Col>
        <Col xs={5} sm={5} md={0}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
            }}
          >
            <MenuOutlined
              className="hamburger"
              onClick={() => setIsExpanded(!isExpanded)}
            />
          </div>
        </Col>
      </Row>

      <Menu
        style={isExpanded ? expandedStyle : collapsedStyle}
        mode="horizontal"
      >
        <Menu.Item onClick={() => setIsExpanded(false)} key="home">
          <Link to="/">
            {selectedOption === "English" ? "Home" : "Accueil"}{" "}
          </Link>
        </Menu.Item>
        <Menu.Item onClick={() => setIsExpanded(false)} key="about">
          <Link to="/about">
            {selectedOption === "English" ? "About" : "À Propos)"}
          </Link>
        </Menu.Item>
        <Menu.Item onClick={() => setIsExpanded(false)} key="database">
          <Link to="/database">
            {selectedOption === "English" ? "Database" : "Base de données"}
          </Link>
        </Menu.Item>

        <div class="dropdown" style={{ height: "100%" }}>
          <button class="dropbtn" style={{ height: "100%" }}>
            {selectedOption === "English" ? "Language ▼" : "Langues? ▼"}
          </button>
          <div class="dropdown-content">
            <label
              type="button"
              className="link-button"
              onClick={() => {
                handleChange("English");
                setIsExpanded(false);
              }}
            >
              {selectedOption === "English" ? "English" : "Anglais"}
            </label>
            <label
              type="button"
              className="link-button"
              onClick={() => {
                handleChange("French");
                setIsExpanded(false);
              }}
            >
              {" "}
              {selectedOption === "English" ? "French" : "Français"}
            </label>
          </div>
        </div>
        <br></br>
        <Menu.Item onClick={() => setIsExpanded(false)} key="contact">
          <Link to="/contact">
            {selectedOption === "English" ? "Contact" : "contacter"}
          </Link>
        </Menu.Item>
      </Menu>
    </>
  );
};

export default HeaderNavigation;
