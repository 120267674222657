import Profile from "./Pages/profile";
import { Layout } from "antd";
import HeaderNavigation from "./Components/headerNavigation";
import Playwright_profile from "./Pages/playwright_profile";
import { Image } from "antd";
import Admin from "./Pages/admin";
import PlawrForm from "./Components/Form";
import Playwrights from "./Pages/playwrights";
import LandingPage from "./Pages/LandingPage";
import Contact from "./Pages/contact";
import InfoPage from "./Pages/InfoPage";
import { Notfound } from "./Pages/Notfound";
import { ReactSVG } from "react-svg";
import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { LangProvider } from "./Context/languagecontext";
import { useMediaQuery } from "react-responsive";
import { useLang, useLangUpdate } from "./Context/languagecontext";
function App() {
  const { Header, Footer, Content } = Layout;
  const selectedOption = useLang();
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  // const [selectedOption, setSelectedOption] = useState('English');

  // const handleOptionChange = (selectedValue) => {

  //   setSelectedOption(selectedValue);
  // };

  let headerStyle = {
    textAlign: "center",
    color: "#fff",
    paddingInline: 0,
    height: "auto",
    backgroundColor: "#2C5632",
    paddingBottom: "0.5vw",
    paddingTop: "0.5vw",
  };

  const contentStyle = {
    color: "#FFFFF3",
    // color:'#2A363B',
    textAlign: "center",
    //  backgroundColor:'#151515',
    backgroundColor: "#FFFFFF",
  };

  const footerStyle = {
    textAlign: "center",
    color: "#fff",
    //backgroundColor: '#7dbcea',
    backgroundColor: "#2C5632",
    display: "flex",
    flexDirection: "Column",
    alignItems: "center",
    justifyContent: "center",
    whiteSpace: "pre-line",
    padding: "0%",
    paddingBottom: "0.75%",
    bottom: 0,
  };

  return (
    <LangProvider>
      <Layout style={{ minHeight: "100vh" }}>
        {/*  <Header style={headerStyle}><HeaderNavigation handleOptionChange={handleOptionChange}></HeaderNavigation></Header>*/}
        {/*  <Content style={contentStyle}><Playwrights selectedOption={selectedOption}/></Content>*/}
        <Header className="header-nav-style" style={headerStyle}>
          <HeaderNavigation />
        </Header>
        <Content style={contentStyle}>
          {" "}
          {/* <Admin /> */}
          <Routes>
            <Route path="/admin" element={<Admin />}></Route>
            <Route path="/" element={<LandingPage />} />
            <Route path="/about" element={<InfoPage />} />
            <Route path="/database" element={<Playwrights />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/playwright" element={<Playwright_profile />} />
            <Route path="*" element={<Notfound />} />
          </Routes>
        </Content>
        <Footer style={footerStyle}>
          {" "}
          <img
            src="./African Theater_Logo_FINAL_White.png"
            width="7%"
            alt="logo"
          />
          <label style={{ fontSize: "1vh" }}>
            {selectedOption === "English"
              ? "Copyright © 2022 – 2023 \n AfricanTheatresinTranslation. All Rights Reserved.\nWebsite by Asish Sasidhar Malneni & Vinay Kalluri | Graphic Art by Shelby Barillas"
              : "Droit d’auteur © 2022 – 2023 \n TheatreAfricainentraduction. Tous droit réservés.\nSite créé par Asish Sasidhar Malneni & Vinay Kalluri | Art graphique par Shelby Barillas"}
          </label>
        </Footer>
      </Layout>
    </LangProvider>
  );
}

export default App;
