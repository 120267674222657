import { React, useState, useRef, useEffect } from "react";
import { Avatar, Button, Input, List, Select, Space, Table } from "antd";
import { Row, Col } from "antd";
import axios from "axios";
import Highlighter from "react-highlight-words";
import {
  SearchOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import _, { uniq } from "lodash";
import FormPopup from "../Components/FormPopup";
import "./admin.css";

const eng_text = ` Daté Atavito Barnabé-Akayi’s dramatic creation, written by dramatists Florent Couao-Zotti or Kangni Alem… and theater specialist Pierre Médéhouégnon or his students Fernand Nouwligbéto and Romain Hounzandji…, promotes a manifestation of Liberty which allows him to show his support to all marginalized minorities, whether they come from modest or fortunate means or are concerned with politics or homosexuality. As the university Mahougnon Kakpo wrote, “Les confessions du PR (The Confessions of PR), like the two others, are both plays which are as good as the renowned playwrights who created them.” His writing strives to care for the human race from the cradle through a better education stripped of permissiveness and resignation.
His well-known published plays, directed by Isidore Dokpa (Fitheb) or Hermas Gbaguidi (Benin, Nigeria, Togo, Ivory Coast) are:
Le chroniqueur du PR (The Chronicler of PR) (2016). 2017 Laureate of the President of the Republic Prize.
Les confessions du PR & Amour en infraction (The Confessions of PR & Love Infraction) (2010), 2017 Finalist of the President of the Republic Prize.
Quand Dieu a faim… (When God is Hungry…) (2010), 2017 Finalist of the President of the Republic Prize. 
Recipient of the 2017 President of the Republic Prize for his play Le chroniqueur du PR, author of about twenty works, Daté Atavito Barnabé-Akayi, born September 24, 1978 in Togo, is a Beninese teacher who won, in 2017-2018, the Grand Price “Tour d’Afrique en 24 fiches” (“Africa Circuit in 24 Cards”) from the Organisation Internationale de la Francophonie (OIF) (International Organization of French Speaking Countries) and the Fédération Internationale des Professeurs de Français (FIPF) (International Federation of French Professors) with an intensive training award for teachers of French foreign language in the Centre d’Approches Vivantes des Langues et des Médias (Center of Living Approaches to Languages and Medias) (Vichy-Alliance Française, France). Currently, he teaches Techniques of Expression and Methods of Communication at the Institut National Supérieur de Technologie Industrielle (INSTI) (National Superior Institute of Industrial Technology) of Lokossa and Université d’Abomey-Calavi (University of Abomey-Calavi) (Benin).  While preparing a Doctorate on the Aesthetic of Esotericism in the poetry of Mahougnon Kakpo, Mwèné Gabriel Okoundji et Nora Atalla, he has published several scientific articles on the theatre of Ousmane Alédji entitled “Analyse des codes théâtraux de la scène 13 de la pièce Omon mi d’Ousmane Alédji” (“Analyze the Theatrical Code of scene 13 in the play Omon mi by Ousmane Alédji”)
`;
const french_text = `La création dramatique de Daté Atavito Barnabé-Akayi, portée par les dramaturges Florent Couao-Zotti ou Kangni Alem… et le théâtrologue Pierre Médéhouégnon ou ses disciples Fernand Nouwligbéto et Romain Hounzandji…, exalte une manifestation de la Liberté qui lui permet de signifier son soutien à toutes les minorités marginalisées, qu’elles viennent des gens modestes ou fortunés, qu’elles concernent la politique ou l’homosexualité. Si l’universitaire Mahougnon Kakpo écrit que « Les confessions du PR, comme les deux autres, sont des pièces qui n’ont rien à envier à ce que des dramaturges de renom ont pu créer », c’est que sa plume travaille la prise en charge de la race humaine dès le berceau par une meilleure éducation dépourvue de laxisme et de fatalisme. 
Ses théâtres édités, connus et mis en scène par Isidore Dokpa (Fitheb) ou Hermas Gbaguidi (Bénin, Niger, Togo, Côte d’Ivoire) sont :
Le chroniqueur du PR (2016). Lauréat du Prix du Président de la République 2017. 
Les confessions du PR & Amour en infraction (2010), Finaliste du Prix du Président de la République 2017.
Quand Dieu a faim… (2010), Finaliste du Prix du Président de la République 2017.

Prix du Président de la République 2017 pour sa pièce théâtrale Le chroniqueur du PR, auteur d’une vingtaine d’ouvrages, Daté Atavito Barnabé-Akayi, né le 24 septembre 1978 au Togo, est un enseignant béninois qui a remporté, en 2017-2018, le Grand Prix « Tour d’Afrique en 24 fiches » de l’Organisation Internationale de la Francophonie (OIF) et de la Fédération Internationale des Professeurs de Français (FIPF) avec une bourse de formation intensive pour enseignants de français langue étrangère au Centre d’Approches Vivantes des Langues et des Médias (Vichy-Alliance Française, France). Actuellement, il enseigne les Techniques d’Expression et Méthodes de Communication à l’Institut National Supérieur de Technologie Industrielle (INSTI) de Lokossa et à l’Université d’Abomey-Calavi (Bénin). Préparant un Doctorat sur l’Esthétique de l’ésotérisme dans la poésie de Mahougnon Kakpo, Mwèné Gabriel Okoundji et Nora Atalla, il a publié quelques articles scientifiques dont un sur le théâtre d’Ousmane Alédji intitulé « Analyse des codes théâtraux de la scène 13 de la pièce Omon mi d’Ousmane Alédji ».
`;

// const dataSource = [
//   {
//     id: "1",
//     name: "Daté Atavito Barnabé-Akayi",
//     country: "Africa",
//     gender: "Male",
//     related_links: [
//       { url: "twitter" },
//       { url: "facebook" },
//       { url: "gpay" },
//       { url: "phonepay" },
//       { url: "threads" },
//       { url: "instagram" },
//     ],
//     bio: eng_text,
//     bio_french: french_text,
//     image: "./1.jpg",
//   },

//   {
//     id: "2",
//     name: "David-Minor ILUNGA",
//     country: "Taiwan",
//     gender: "Male",
//     related_links: [
//       { url: "twitter" },
//       { url: "facebook" },
//       { url: "gpay" },
//       { url: "phonepay" },
//       { url: "threads" },
//       { url: "instagram" },
//     ],
//     image: "./David Minor Ilunga.jpg",
//     bio: eng_text,
//     bio_french: french_text,
//   },
//   {
//     id: "3",
//     name: "Edouard Elvis Bvouma",
//     country: "India",
//     gender: "Male",
//     related_links: [
//       { url: "twitter" },
//       { url: "facebook" },
//       { url: "gpay" },
//       { url: "phonepay" },
//       { url: "threads" },
//       { url: "instagram" },
//     ],
//     image: "./Portrait EEBvouma-Christophe Péan.jpg",
//     bio: eng_text,
//     bio_french: french_text,
//   },
//   {
//     id: "4",
//     name: "David-Minor ILUNGA",
//     country: "usa",
//     gender: "Male",
//     related_links: [],
//     image: "./David Minor Ilunga.jpg",
//     bio: eng_text,
//     bio_french: french_text,
//   },
//   {
//     id: "5",
//     name: "Edouard Elvis Bvouma",
//     country: "denmark",
//     gender: "Male",
//     related_links: [],
//     image: "./Portrait EEBvouma-Christophe Péan.jpg",
//     bio: eng_text,
//     bio_french: french_text,
//   },
//   {
//     id: "6",
//     name: "David-Minor ILUNGA",
//     country: "Africa",
//     gender: "Male",
//     related_links: [
//       { url: "twitter" },
//       { url: "facebook" },
//       { url: "gpay" },
//       { url: "phonepay" },
//       { url: "threads" },
//       { url: "instagram" },
//     ],
//     image: "./David Minor Ilunga.jpg",
//     bio: eng_text,
//     bio_french: french_text,
//   },
//   {
//     id: "7",
//     name: "Edouard Elvis Bvouma",
//     country: "Africa",
//     gender: "Male",
//     related_links: [],
//     image: "./Portrait EEBvouma-Christophe Péan.jpg",
//     bio: eng_text,
//     bio_french: french_text,
//   },
// ];

const Admin = () => {
  const [openpopup, setopenpopup] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [selectedplaywright, setselectedplaywright] = useState({
    id: "",
    name: "",
    country: "",
    gender: "",
    related_links: "",
    bio: "",
    bio_french: "",
    image: "",
  });
  //filter and sorting code for the table
  const [seldrop, setseldrop] = useState("Playwrights");
  console.log({ seldrop });
  const handledropChange = (value) => {
    setseldrop(value);
    console.log({ seldrop });
  };

  const deletePlaywright = async (record) => {
    console.log("record: ", record);
    try {
      let result = await axios.delete(
        process.env.REACT_APP_BASE_URL +
          `/api/v1/playwright/delete-playwright/${record.id}`
      );
      console.log("resul: ", result);
    } catch (err) {
      console.log(err);
    }
  };

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const filterData = (data) => (formatter) =>
    data.map((item) => ({
      text: formatter(item),
      value: formatter(item),
    }));
  // the below constant is for dropdown of playwrights and Team
  const [TeamdataSource, setTeamdataSource] = useState([]);
  //fetch info
  useEffect(() => {
    const fetchData = async () => {
      const data = await axios.get(
        process.env.REACT_APP_BASE_URL + "/api/v1/team/get-members"
      );
      console.log("da: ", data);
      // data.image_path = data.image_path ? data.image_path : "";
      // console.log(
      //   "data: ",
      //   process.env.REACT_APP_BASE_URL + "/" + data[0].image_path
      // );
      let team = data.data.map((item) => ({
        image: item.image_path == null || undefined ? "" : item.image_path,
        ...item,
      }));
      console.log("team: ", team);
      setTeamdataSource(team);
    };

    // call the function
    fetchData();

    //fetch playwrights

    const fetchPlaywrights = async () => {
      const data = await axios.get(
        process.env.REACT_APP_BASE_URL + "/api/v1/playwright/get-playwrights"
      );
      console.log("da: ", data);
      // data.image_path = data.image_path ? data.image_path : "";
      // console.log(
      //   "data: ",
      //   process.env.REACT_APP_BASE_URL + "/" + data[0].image_path
      // );
      let plays = data.data.map((item) => ({
        image: item.image_path == null || undefined ? "" : item.image_path,
        ...item,
      }));
      setDataSource(plays);
    };
    fetchPlaywrights();
  }, []);

  const Teamcolumns = [
    {
      title: "Picture",
      dataIndex: "image",
      key: "image",

      render: (text, record) => {
        console.log("record: ", record);
        return (
          <Avatar
            src={process.env.REACT_APP_BASE_URL + "/" + record.image_path}
            size={64}
            shape="square"
          />
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Role",
      dataIndex: "development_role",
      key: "development_role",
      filters: _.uniqWith(
        filterData(TeamdataSource)((i) => i.development_role),
        _.isEqual
      ),
      onFilter: (value, record) => record.development_role.startsWith(value),

      filterSearch: true,
    },

    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (_, record) => (
        <Space>
          {" "}
          <Button
            size="small"
            icon={<EditOutlined />}
            type="primary"
            style={{ fontSize: "90%" }}
            onClick={() => {
              setopenpopup(true);
              setselectedplaywright(record);
            }}
          >
            Edit
          </Button>{" "}
          <Button
            size="small"
            icon={<UserDeleteOutlined />}
            style={{ fontSize: "90%" }}
            type="primary"
          >
            Delete
          </Button>{" "}
        </Space>
      ),
    },
  ];

  const Teamcolumnsexp = [
    {
      title: "Bio",
      dataIndex: "bio",
      key: "bio",
      render: (_, record) => (
        <p dangerouslySetInnerHTML={{ __html: JSON.stringify(record.bio) }}></p>
      ),
    },
    {
      title: "bio_french",
      dataIndex: "bio_french",
      key: "bio_french",
      render: (_, record) => (
        <p dangerouslySetInnerHTML={{ __html: record.bio_french }}></p>
      ),
    },
  ];

  const columns = [
    {
      title: "Picture",
      dataIndex: "image",
      key: "image",

      render: (text, record) => (
        <Avatar
          src={process.env.REACT_APP_BASE_URL + "/" + record.image_path}
          size={64}
          shape="square"
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      filters: [
        {
          text: "London",
          value: "London",
        },
        {
          text: "New York",
          value: "New York",
        },
        {
          text: "Africa",
          value: "Africa",
        },
      ],
      onFilter: (value, record) => record.country.startsWith(value),

      filterSearch: true,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      //filters: filterData(dataSource)(i => i.gender),
      filters: _.uniqWith(
        filterData(dataSource)((i) => i.gender),
        _.isEqual
      ),
      onFilter: (value, record) => record.gender.startsWith(value),
      filterSearch: true,
    },
    {
      title: "related_links",
      dataIndex: "related_links",
      key: "related_links",
      render: (_, { related_links }) => (
        <>
          {related_links.map((link) => {
            return (
              <List style={{ listStyleType: "square" }}>
                <List.Item>{link.url}</List.Item>
              </List>
            );
          })}
        </>
      ),
    },

    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (_, record) => (
        <Space>
          {" "}
          <Button
            size="small"
            icon={<EditOutlined />}
            type="primary"
            style={{ fontSize: "90%" }}
            onClick={() => {
              setopenpopup(true);
              setselectedplaywright(record);
            }}
          >
            Edit
          </Button>{" "}
          <Button
            size="small"
            icon={<UserDeleteOutlined />}
            style={{ fontSize: "90%" }}
            type="primary"
            onClick={() => deletePlaywright(record)}
          >
            Delete
          </Button>{" "}
        </Space>
      ),
    },
  ];

  const columnsexp = [
    {
      title: "Bio",
      dataIndex: "bio",
      key: "bio",
      render: (_, record) => (
        <p dangerouslySetInnerHTML={{ __html: record.bio }}></p>
      ),
    },
    {
      title: "bio_french",
      dataIndex: "bio_french",
      key: "bio_french",
      render: (_, record) => (
        <p dangerouslySetInnerHTML={{ __html: record.bio_french }}></p>
      ),
    },
  ];

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            marginRight: "2%",
            marginTop: "0.5%",
            marginBottom: "0.5%",
          }}
        >
          <Select
            style={{ marginRight: "1%" }}
            defaultValue={seldrop}
            onChange={handledropChange}
            options={[
              {
                value: "Playwrights",
                label: "Playwrights",
              },
              {
                value: "Team",
                label: "Team",
              },
            ]}
          />
          <Button
            onClick={() => {
              setopenpopup(true);
              setselectedplaywright("");
            }}
            type="primary"
            icon={<UserAddOutlined />}
            style={{
              fontSize: "100%",
              backgroundColor: "#B2B2B2",
              color: "black",
            }}
          >
            Add New
          </Button>
        </div>
        <Table
          dataSource={seldrop === "Playwrights" ? dataSource : TeamdataSource}
          columns={seldrop === "Playwrights" ? columns : Teamcolumns}
          bordered
          size="large"
          className="my-table"
          expandable={{
            expandedRowRender: (selrecord) => (
              <Table
                dataSource={
                  seldrop === "Playwrights"
                    ? dataSource.filter((record) =>
                        record.id.match(selrecord.id)
                      )
                    : TeamdataSource.filter((record) =>
                        record.id.match(selrecord.id)
                      )
                }
                columns={
                  seldrop === "Playwrights" ? columnsexp : Teamcolumnsexp
                }
                pagination={false}
                bordered
                size="small"
              />
            ),
          }}
        />
        ;
      </div>

      {openpopup && (
        <FormPopup
          setclosebutton={setopenpopup}
          selectedrecord={selectedplaywright}
          isPlaywright={true}
          style={{}}
        ></FormPopup>
      )}
    </>
  );
};

export default Admin;
